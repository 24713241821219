.heading,
.heading * {
  margin-bottom: 8px;
  font-weight: 600;
  line-height: 1.1;
}

.noMargin,
.noMargin * {
  margin: 0;
}

.color-dark,
.color-dark * {
  color: rgb(39, 41, 45);
}

.color-light,
.color-light * {
  color: rgb(255, 255, 255);
}

.color-gray,
.color-gray * {
  color: rgb(97, 97, 97);
}

.uppercase {
  text-transform: uppercase;
}

.centered {
  text-align: center;
}

.level-h1,
.level-h1 * {
  font-weight: 700;
  font-size: 40px;
}

.level-h2,
.level-h2 * {
  font-size: 30px;
}

.level-h3,
.level-h3 * {
  font-weight: bold;
  font-size: 22px;
}

.level-h4,
.level-h4 * {
  font-weight: bold;
  font-size: 18px;
}

.level-h5,
.level-h5 * {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
}
