.wrapper {
  width: 100%;
  margin: 50px auto 0;
}

.title {
  margin: 0;
  padding-bottom: 0;
  color: var(--colors-secondary-900);
  font-weight: bold;
  font-size: 15px;
  line-height: 110%;
}

.logos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  margin-top: 20px;
  filter: brightness(80%);
}

.theAthletic {
  grid-row-start: 1;
  grid-column-start: 5;
}

.item {
  cursor: pointer;
}

.item:hover {
  filter: brightness(60%);
  transition: all 350ms ease;
}

@media only screen and (max-width: 699px) {
  .logos {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    padding-right: 20px;
  }

  .theAthletic {
    grid-row-start: 2;
    grid-column-start: 1;
  }

  .theAthletic img {
    width: 120px;
  }
}
